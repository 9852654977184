<template>
  <tr class="heighRow">
    <td style="width: 10%">
      <div class="d-flex justify-content-center">
        <span v-text="orderItem.count" class="text-center"></span>
      </div>
    </td>
    <td style="width: 40%; padding: 0 20px 0 20px">
      <div v-if="edit">
        <input
          style="height: calc(1.35rem + 1.1rem + 2px); border-color: #007bff"
          v-model="itemChoice.name"
          placeholder="Tên người mua"
          class="w-100 h-90 input"
          oninvalid="alert('Nhập đầy đủ thông tin!');"
        />
        <p style="color: red" v-if="error">Hãy nhập đầy đủ thông tin</p>
      </div>
      <span v-else>{{ orderItem.name }}</span>
    </td>
    <td style="width: 35%">
      <div class="d-flex justify-content-center">
        <div v-if="edit">
          <b-form-select
            v-model="itemChoice.status"
            :options="[
              { value: 1, text: 'Hoạt động' },
              { value: 2, text: 'Ngừng Hoạt động' },
            ]"
          ></b-form-select>
        </div>
        <div v-else>
          <span v-if="orderItem.status === 1">
            <span class="label label-dot label-primary mr-2"></span>
            <span class="font-bold font-primary">{{
              htmlGetStatus(orderItem.status)
            }}</span>
          </span>
          <span v-else>
            <span class="label label-dot label-danger mr-2"></span>
            <span class="font-bold font-danger">{{
              htmlGetStatus(orderItem.status)
            }}</span>
          </span>
        </div>
      </div>
    </td>
    <td>
      <template v-if="edit">
        <div class="d-flex justify-content-center">
          <v-icon
            class="mr-2 text-primary"
            style="font-size: 18px"
            @click="checkvalidation()"
            >mdi-check</v-icon
          >
          <v-icon
            class="text-danger"
            style="font-size: 18px"
            @click="cancelEdit()"
            >mdi-close</v-icon
          >
        </div>
      </template>

      <template v-else>
        <div class="d-flex justify-content-center">
          <b-dropdown size="sm" id="dropdown-left" no-caret right>
            <template slot="button-content">
              <i
                style="font-size: 1rem; padding-right: 0px"
                class="flaticon2-settings"
              ></i>
            </template>
            <b-dropdown-item
              @click="editItem()"
              v-show="checkPermission('ORDER_SOURCE_UPDATE')"
            >
              <span style="color: #3f4254; font-size: 12px">
                <i style="font-size: 1rem" class="flaticon2-pen"></i>
                &nbsp; Chỉnh sửa
              </span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="showDeleteAlert"
              v-show="checkPermission('ORDER_SOURCE_DELETE')"
            >
              <span style="color: #3f4254; font-size: 12px">
                <i
                  style="font-size: 1rem; color: #d33"
                  class="flaticon2-rubbish-bin-delete-button"
                ></i>
                &nbsp; Xóa
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </td>
  </tr>
</template>

<style>
.heighRow {
  height: 40px;
}

.input {
  border: 1px solid #ecf0f3;
  border-radius: 5px;
  padding-left: 5px;
}

*:focus {
  outline: none;
}
</style>

<script>
import Swal from 'sweetalert2';

export default {
  props: ['orderItem', 'listFeature'],
  data() {
    return {
      edit: false,
      itemChoice: this.setDefaultItemChoice(),
      error: false,
    };
  },
  updated() {
    this.checkAddRow();
  },
  methods: {
    setDefaultItemChoice() {
      return {
        id: 0,
        code: '',
        name: '',
        status: 1,
        createBy: '',
        createDate: '',
      };
    },
    htmlGetStatus(status) {
      return status === 1 ? 'Hoạt động' : 'Ngừng hoạt động';
    },
    editItem() {
      this.edit = true;
      this.itemChoice = {
        ...this.orderItem,
      };
    },
    cancelEdit: function () {
      this.error = false;
      this.edit = false;
      if (this.orderItem.name == '') {
        this.$emit('cancelAdd');
      }
      this.itemChoice = this.setDefaultItemChoice();
    },
    updateItem: function () {
      this.edit = false;
      if (this.orderItem.id == 0) {
        this.orderItem.name = this.itemChoice.name;
        this.orderItem.status = this.itemChoice.status;
        this.$emit('addItem', this.itemChoice);
      } else {
        this.$emit('updateItem', this.itemChoice);
      }
      this.itemChoice = this.setDefaultItemChoice();
    },
    checkvalidation() {
      if (this.itemChoice.name) {
        this.error = false;
        this.updateItem();
      } else {
        this.error = true;
      }
    },
    showDeleteAlert: function () {
      Swal.fire({
        title: 'Xóa nguồn đơn hàng!',
        text: 'Bạn có chắc muốn xóa nguồn đơn hàng này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.$emit('deleteItem', this.orderItem.id);
        }
      });
    },
    checkPermission(condition) {
      if (this.listFeature.indexOf(condition) > -1) {
        return true;
      } else {
        return false;
      }
    },
    checkAddRow() {
      if (this.orderItem.name == '') {
        this.edit = true;
      } else if (this.edit) {
        this.edit = true;
      } else {
        this.edit = false;
      }
    },
  },
};
</script>
